import partnerLogo from "../../assets/images/partners/microsoft.png";
import { Markets } from "./markets";
import { Roles } from "./roles";
import { Titles } from "./titles";
const DEFAULT_COVER = require("../../assets/images/about-profile-default.png");

export const Event = {
  eventName: "[EventName]",
  eventDescription: "[Some Event Details Here .........]",
  additionalDetails: "[Add Event Additional Details Here ........]",
  startDate: "Start Date",
  endDate: "End Date",
  nextYear: 2023,
  timeLineAndPrizes: [
    {
      title: "timeline-title-1",
      description: "timeline and prizes description - 1"
    },
    {
      title: "timeline-title-2",
      description: "timeline and prizes description - 2"
    },
    {
      title: "timeline-title-3",
      description: "timeline and prizes description - 3"
    },
    {
      title: "timeline-title-4",
      description: "timeline and prizes description - 4"
    }
  ],
  faqSections: [
    {
      name: "About Data Privacy Week",
      sectionData: [
        {
          title: "What is Data Privacy Week?",
          body: [
            {
              itemText:
                "Data Privacy Day is January 28th.  It is “an international effort to create awareness about the importance of respecting privacy, safeguarding data, and enabling trust.” Privacy @ Slalom is hosting a week long event geared to promote data privacy awareness and have fun doing so!  We are starting with Privacy Training that will be the perfect tool in participating in our Privacy themed event platform!   It is a specially developed space geared to Slalomers to engage in Data Privacy activities.  Join us on a Privacy Mission since we are all a vital part of Data Privacy!"
            }
          ]
        },
        {
          title: "What is this platform?",
          body: [
            {
              itemText:
                "This platform may look familiar to you if you participated in events utilizing the Events Platform last year, or Hacktober. Our events platform blends cybersecurity with Slalom events to bring a fun, unique way to interact with our ERG and ID&E groups!"
            }
          ]
        },
        {
          title:
            "I need accommodation due to neurodiversity, visual & audio impairment, etc. What should I do?",
          body: [
            {
              itemText:
                "Please email hacktober@slalom.com   and include the accommodations you need to enable your participation."
            }
          ]
        }
      ]
    },
    {
      name: "Activities",
      sectionData: [
        {
          title: "What is a Question of the Day?",
          body: [
            {
              itemText:
                "The Question of the Day is a daily quiz during Data Privacy Week that, if answered correctly, will earn you points. The questions refresh every 12 hours, which is unique to this event, so be sure to check back every business day for a chance to earn points!"
            }
          ]
        },
        {
          title: "Are all the events global and open to everyone?",
          body: [
            {
              itemText:
                "Yes -- all events are virtual and the time for each event is reflected in your time zone!"
            }
          ]
        },
        {
          title: "How do I sign up for an event?",
          body: [
            {
              itemText:
                "Review the events calendar on the Events page and select “Download Invite” for the events you would like to attend. This button will take you to that event's SharePoint page, where you can then select the “Add To Calendar” link and an .ics file will download to your machine and you can save it to your calendar. If you are having trouble, please email privacy@slalom.com  and the team can forward you the invite directly."
            }
          ]
        }
      ]
    },
    {
      name: "Site",
      sectionData: [
        {
          title: "Where can I go to find Event Recordings?",
          body: [
            {
              itemText:
                "All recordings will be posted via the “View Recordings” button on the Events page. Please note that event recordings are entered manually, and may take up to 4 business days to be uploaded to the playlist."
            }
          ]
        },
        {
          title:
            "Can I invite clients or people external to Slalom to this event?",
          body: [
            {
              itemText:
                "No, please do not share any meetings or events externally! The Events Platform is built exclusively for Slalom employees."
            }
          ]
        },
        {
          title:
            "If I watch an event recording, can I still use the code to earn points?",
          body: [
            {
              itemText:
                "Yes -- event codes will only work until the end of DPW however, so please be sure to input your code by then."
            }
          ]
        }
      ]
    },
    {
      name: "Points & Prizes",
      sectionData: [
        {
          title: "How do you earn points for Data Privacy Week?",
          body: [
            {
              itemText:
                'There are many ways to earn points. You can earn points by answering the Question of the Day, attending events, and completing activities listed under "Other Ways to Earn Points."'
            }
          ]
        },
        {
          title:
            "Who do I contact if I think my point total is tallied incorrectly?",
          body: [
            {
              itemText:
                'If you feel that you have an incorrect point total, please reach out to zenith@slalom.com   and our development team will investigate! Please note that the "Other ways to earn points" section has points added manually, so we appreciate your patience as those get added.'
            }
          ]
        },
        {
          title: "What prizes are available?",
          body: [
            {
              itemText:
                "There are 5 total prizes for the top Global Winners. The prizes are as follows: \n\n\t1st Place Global Winner: AirPods!\n\n\t2nd Place Global Winner: $100\n\n\t3rd Place Global Winner: $50\n\n\t4th Place Global Winner: $25\n\n\t5th Place Global Winner: $25"
            }
          ]
        },
        {
          title: "When will winners be announced?",
          body: [
            {
              itemText:
                "Winners will be announced by mid-February, and prizes will be distributed by March. Please look out for an email if you've won!"
            }
          ]
        }
      ]
    },
    {
      name: "Contact",
      sectionData: [
        {
          title: "Who can I contact if I am having issues with the site?",
          body: [
            {
              itemText:
                "Please email zenith@slalom.com  with screenshots and a thorough description of the issue, and someone from our team will work to troubleshoot."
            }
          ]
        },
        {
          title: "I have a question about an event. Who can I contact?",
          body: [
            {
              itemText: "Please email privacy@slalom.com "
            }
          ]
        },
        {
          title: "Who can I contact regarding Data Privacy Week questions?",
          body: [
            {
              itemText: "Please email privacy@slalom.com "
            }
          ]
        },
        {
          title:
            "Who developed this events platform, and how can I get involved?",
          body: [
            {
              itemText:
                "A wonderful team of volunteers brought this platform to life. You can check them out by visiting the About page. Interested in participating in the next events platform build? Sign up via the about page!"
            }
          ]
        },
        {
          title:
            "I’d love to use the Event Platform for the work I’m doing. Who can I reach out to?",
          body: [
            {
              itemText:
                "Please sign up for our waitlist via our Microsoft Form event-waitlist"
            }
          ]
        }
      ]
    }
  ],
  partnersList: [
    {
      name: "Microsoft",
      link:
        "https://twodegrees1.sharepoint.com/teams/GlobalAlliances/SitePages/Microsoft.aspx",
      image: partnerLogo
    }
  ],
  aboutPage: {
    eventIntroTextOne: `The Privacy @ Slalom team is proud to present Slalom's second annual Data Privacy Week celebration, which is a global Slalom initiative dedicated to building data privacy awareness in entertaining and engaging ways! Data Privacy Day is an annual celebration on Jan 28th. Our mission is to weave privacy into the fabric of Slalom's culture and help cultivate a privacy matters mindset.`,
    eventIntroSecondaryTextOne: `Have questions? Contact privacy@slalom.com for more information.`,
    eventIntroTextTwo: `Information Security and Governance is proud to unveil the first ever Slalom Events Platform, dedicated to bringing Slalom together in fun, secure, and meaningful ways! The Events Platform first began as Hacktober in 2019. Created by Reedhima Mandlik, Hacktober is a way to make cybersecurity engaging and fun. Hacktober has since grown to be a Slalom Women-led initiative in partnership with the Information Security and Governance team, and evolved into the Events Platform to help connect cybersecurity and Slalom Events at a global scale. Our mission is to weave cybersecurity into the fabric of Slalom’s culture and help cultivate a security-first mindset. Together, we are all a vital part of the InfoSec team!`,
    aboutEventsFeedbackText: `We always welcome feedback on our platform or the events we are hosting! Feel free to contact us.`,
    aboutEventsJoinText: `Like what you see? Want experience building a custom platform in React? We welcome team members from every capability. Sign up via our link below!`,
    aboutEventsBugText: `Please report all bugs through our link below!`,
    aboutTeams: {
      Leadership: [
        {
          fullName: "Reedhima Mandlik",
          role: `${Roles.leadership.programDirector}/${Roles.leadership.founder}`,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/reedhima-mandlik.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Isabella Antonini",
          role: `${Roles.leadership.solutionArchitect}`,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/isabella-antonini.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Jasmin Marun",
          role: `${Titles.programManager}, ${Roles.leadership.dataPrivacy}`,
          market: Markets.miami,
          professionalPhoto: require("../../assets/images/team/jasmin-marun.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Farah Rehmat",
          role: `${Titles.analyst}, ${Roles.leadership.dataPrivacy}`,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team/farah-rehmat.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Amber Tamir",
          role: `${Titles.corporateCouncil}, ${Roles.leadership.legal}`,
          market: Markets.dallas,
          professionalPhoto: require("../../assets/images/team/amber-tamir.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Elizabeth McLarty",
          role: `${Titles.principal}, ${Roles.leadership.operations}`,
          market: Markets.nyc,
          professionalPhoto: require("../../assets/images/team/elizabeth_mclarty.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        }
      ],
      Design: [
        {
          fullName: "Timur Dzhambinov",
          role: Roles.lvd,
          market: Markets.atlanta,
          professionalPhoto: require("../../assets/images/team/timur-dzhambinov.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Karen Wittekind",
          role: Roles.xd,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/karen-wittekind.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Rye Crowen",
          role: Roles.xd,
          market: Markets.philadelphia,
          professionalPhoto: require("../../assets/images/team/rye-crowen.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        }
      ],
      Development: [
        {
          fullName: "Erasmo Velez Baerga",
          role: Roles.smeDatabase,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/erasmo-baerga.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Kevin Orlando",
          role: Roles.smeArchitect,
          market: Markets.charlotte,
          professionalPhoto: require("../../assets/images/team/kevin-orlando.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Jonathan Olmon",
          role: Roles.softwareEngineer,
          market: Markets.houston,
          professionalPhoto: require("../../assets/images/team/jonathan-olmon.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Danny Martinez",
          role: Roles.softwareEngineer,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/danny-martinez.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        }
      ]
    }
  },
  winnersPage: {
    title: "welcome to data privacy week",
    section1Title: "Thank you for participating !",
    section1Description:
      "Thank you so much for your participation in Slalom's Data Privacy Week. We hope that you found it engaging and informative. If you have any feedback or questions, please contact",
    feedbackTitle: "Got Feedback?",
    feedbackText: `We always welcome feedback on our platform or the events we are hosting! Feel free to contact us.`,
    joinTitle: "Join the Zenith Build Team!",
    joinText: `Like what you see? Want experience building a custom platform in React? We welcome team members from every capability. Sign up via our link below!`,
    bugTitle: "Caught a bug?",
    bugText: `Please report all bugs through our link below!`
  },
  wrapPage: {
    title: "Thank you from the DPW and InfoSec Team!",
    message: `Thank you so much for your participation in Slalom's Data Privacy Week. We hope that you found it engaging and informative. If you have any feedback or questions, please contact`,
    sharePointLink:
      "https://twodegrees1.sharepoint.com/teams/PrivacySlalom/DPW%202024%20Event%20Recordings/Forms/All%20media.aspx?npsAction=createList",
    signUpLink:
      "https://sparkthink.slalom.com/survey/s/4b2b2497-306a-4311-886b-5a6b4cbc4d53"
  },
  preLaunchPage: {
    message: `Event specific pre-launch message here: Lorem ipsum dolor sit amet,
    consectetur adipiscing elit. Adipiscing auctor rhoncus, massa
    condimentum nibh amet, pulvinar.`,
    spotifyPlayList: "",
    videoLink: ""
  }
};
