import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getMarkets, getParticipant, getParticipants } from "../../ApiHelper";
import { ReactComponent as GlobalIcon } from "../../assets/images/network.svg";
import { ReactComponent as MarketIcon } from "../../assets/images/people.svg";
import { ReactComponent as LocalIcon } from "../../assets/images/person.svg";
import DropdownFilter from "../Dropdown";
import LoadingSpinner from "../LoadingSpinner";

import { otherLeaderParticipants, sortedRankedPartcipants } from ".";
import { showWinners } from "../../utils/eventUtil";
import { getDataImage } from "../../utils/imageUtils";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import Card from "../Card/Card";
import Tooltip from "../Tooltip/Tooltip";
import TopLeaders from "../TopLeaders/TopLeaders";
import "./Leaderboard.scss";
import { ELeaderboardOption } from "./Leaderboard.util";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const leaderBoardContainerResponsiveClassName = width =>
  `leaderboard-container leaderboard-container-${getResponsiveClassName(
    width
  )}`;

const leaderListingContainerClassName = width =>
  `leader-listing-container${width > TABLET_MAX ? "" : "-mobile"}`;

const sectionTitleString = isLocalLeaderBoard =>
  isLocalLeaderBoard ? "LOCAL WINNERS" : "PARTICIPANT LEADERBOARD";

type LeaderProps = {
  rank: Number;
  image: any;
  firstName: string;
  lastName: string;
  name: string;
  market: string;
  totalPoints: Number;
  selectedOption: "Global" | "Market" | "Local";
};

const LeaderListing: FC<LeaderProps> = ({
  rank,
  image,
  firstName,
  lastName,
  name,
  market,
  totalPoints,
  selectedOption
}: LeaderProps) => {
  return (
    <div
      className={`leader-listing ${selectedOption === "Market" &&
        "leader-listing-market"}`}
    >
      <div className="listing-rank listing-text-style">{rank}</div>
      <div className="listing-participant ">
        {selectedOption !== "Market" && (
          <img
            src={getDataImage(image)}
            className="listing-avatar"
            alt="avatar"
          />
        )}
        <div className="listing-text-style">{`${name ||
          `${firstName} ${lastName}`}`}</div>
      </div>
      <div className="listing-text-alt">{market}</div>
      <div className="listing-text-alt">{totalPoints}</div>
    </div>
  );
};

type LoadLeadersProps = {
  width: any;
  sortedParticipants: any;
  selectedOption: ELeaderboardOption;
  error: any;
  isLoading: boolean;
};

const LoadLeaderListing: FC<LoadLeadersProps> = ({
  width,
  sortedParticipants,
  selectedOption,
  error,
  isLoading
}: LoadLeadersProps) => {
  return (
    <div className={leaderListingContainerClassName(width)}>
      {sortedParticipants &&
        otherLeaderParticipants(sortedParticipants).map((participant, idx) => (
          <LoadingSpinner
            key={idx}
            isLoading={isLoading}
            error={error}
            color={"#00A4F9"}
            size={"small"}
          >
            <LeaderListing
              key={participant.user}
              {...participant}
              selectedOption={selectedOption}
              rank={idx + 4}
            />
          </LoadingSpinner>
        ))}
    </div>
  );
};

type LeaderboardOrFallbackProps = {
  selectedOption: "Local" | "Global" | "Market" | string;
  isLocalLeaderBoard: Boolean;
  sortedParticipants: Array<any>;
  error: any;
  isLoading: boolean;
};

const LeaderboardOrFallback: FC<LeaderboardOrFallbackProps> = ({
  isLocalLeaderBoard,
  sortedParticipants,
  selectedOption,
  error,
  isLoading
}: LeaderboardOrFallbackProps) => {
  const { width } = useWindowSize();
  return sortedParticipants && sortedParticipants?.length > 0
    ? renderLeaderBoard(
        isLocalLeaderBoard,
        width,
        sortedParticipants,
        selectedOption,
        error,
        isLoading
      )
    : renderFallbackMessage();
};

const renderLeaderBoard = (
  isLocalLeaderBoard,
  width,
  sortedParticipants,
  selectedOption,
  error,
  isLoading
) => (
  <>
    <TopLeaders
      width={width}
      sortedParticipants={sortedParticipants}
      selectedOption={selectedOption}
      error={error}
      isLoading={isLoading}
    />
    {width > MOBILE_MAX && !isLocalLeaderBoard && (
      <LoadLeaderListing
        width={width}
        sortedParticipants={sortedParticipants}
        selectedOption={selectedOption}
        error={error}
        isLoading={isLoading}
      />
    )}
  </>
);

const renderFallbackMessage = () => (
  <div className="leaderboard-default-msg">
    Want to see your name on the leaderboard? Check out our ways to participate
    to get started!
  </div>
);

type LeaderboardOptionsProps = {
  selectedOption: string;
  setSelectedOption: (selectedOption: ELeaderboardOption) => void;
};

const LeaderboardOptions: FC<LeaderboardOptionsProps> = ({
  selectedOption,
  setSelectedOption
}: LeaderboardOptionsProps): JSX.Element => {
  const isSelectedStyleApplied = (option: string) =>
    option === selectedOption
      ? "selected-leaderboard-option"
      : "unselected-leaderboard-option";
  const leaderboardOptions = showWinners
    ? [
        {
          title: "Global",
          icon: <GlobalIcon />,
          type: ELeaderboardOption.Global
        }
      ]
    : [
        {
          title: "Global",
          icon: <GlobalIcon />,
          type: ELeaderboardOption.Global
        },
        {
          title: "Market",
          icon: <MarketIcon />,
          type: ELeaderboardOption.Market
        },
        { title: "Local", icon: <LocalIcon />, type: ELeaderboardOption.Local }
      ];
  return (
    <div className="leaderboard-options">
      {leaderboardOptions.map(leaderboardOption => {
        const { title, icon, type } = leaderboardOption;
        return (
          <div
            key={title}
            className={`leaderboard-option ${isSelectedStyleApplied(title)}`}
            onClick={() => setSelectedOption(type)}
          >
            <span className="leaderboard-option-icon">{icon}</span>
            {title}
          </div>
        );
      })}
    </div>
  );
};

const renderMobileLeaderBoardOptions = (
  width: number,
  setSelectedOption: (selectedOption: ELeaderboardOption) => void
) => {
  const leaderboardOptions = [
    { text: "Global", value: ELeaderboardOption.Global },
    { text: "Market", value: ELeaderboardOption.Market },
    { text: "Local", value: ELeaderboardOption.Local }
  ];
  return width <= MOBILE_MAX ? (
    <div className="leaderboard-options-mobile">
      <DropdownFilter
        placeholder="Global"
        defaultValue="Global"
        onChange={(event, data) =>
          setSelectedOption(data.value as ELeaderboardOption)
        }
        options={leaderboardOptions}
        selection
        label=""
      />
    </div>
  ) : null;
};

type LeaderboardProps = {
  isLocalLeaderBoard: Boolean;
};

const Leaderboard: FC<LeaderboardProps> = ({
  isLocalLeaderBoard = false
}: LeaderboardProps) => {
  const { width } = useWindowSize();
  const [selectedOption, setSelectedOption] = useState(
    ELeaderboardOption.Global
  );
  const {
    data: globalData,
    error: globalError,
    isLoading: globalIsLoading
  } = useQuery(["global", "Global"], getParticipants);
  const { data: participantData } = useQuery("participant", getParticipant);
  const {
    data: localData,
    error: localError,
    isLoading: localIsLoading
  } = useQuery(["local", participantData?.market], getParticipants);

  const {
    data: marketsData,
    error: marketsError,
    isLoading: marketsIsLoading
  } = useQuery(["markets"], getMarkets);

  let participants = null;
  let error = null;
  let isLoading = null;
  if (selectedOption === ELeaderboardOption.Global) {
    participants = globalData;
    error = globalError;
    isLoading = globalIsLoading;
  } else if (selectedOption === ELeaderboardOption.Market) {
    participants = marketsData;
    error = marketsError;
    isLoading = marketsIsLoading;
  } else if (selectedOption === ELeaderboardOption.Local) {
    participants = localData;
    error = localError;
    isLoading = localIsLoading;
  }

  let sortedParticipants = null;
  if (showWinners) {
    sortedParticipants =
      participants && sortedRankedPartcipants(participants).slice(0, 5);
  } else {
    sortedParticipants = participants && sortedRankedPartcipants(participants);
  }

  return (
    <div className="section-container">
      <div className="section-header">
        <div className="section-title">
          {sectionTitleString(isLocalLeaderBoard)}
        </div>

        <Tooltip>
          <p className="LeadershipboardToolTip">
            This leaderboard updates in real time. To learn more, check out the{" "}
            <Link to="/faqs">FAQ page</Link>.
          </p>
        </Tooltip>
      </div>

      {width > MOBILE_MAX ? (
        <LeaderboardOptions
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      ) : null}
      <Card>
        <div
          className={`${leaderBoardContainerResponsiveClassName(
            width
          )} ${selectedOption}-container`}
        >
          {renderMobileLeaderBoardOptions(width, setSelectedOption)}
          <LeaderboardOrFallback
            selectedOption={selectedOption}
            isLocalLeaderBoard={isLocalLeaderBoard}
            sortedParticipants={sortedParticipants}
            isLoading={isLoading}
            error={error}
          />
        </div>
      </Card>
    </div>
  );
};

export default Leaderboard;
