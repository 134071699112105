import React from "react";
import { isBeforeEventStart } from "../../utils/eventUtil";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import PageBanner from "../PageBanner/PageBanner";
import ScrollToTopButton from "../ScrollToTopButton/ScrollToTopButton";
import "./PageWrapper.scss";

// Adjust the margins for the page wrapper depending on if the header/footer are visible
const getDynamicWrapperClass = (baseClass: string, noHeader: boolean): string =>
  noHeader ? `${baseClass}-prelaunch` : `${baseClass}-fullsite`;

interface PageWrapperProps {
  children: any;
  bannerImage: string;
}

const PageWrapper = ({ bannerImage, children }: PageWrapperProps) => {
  const { width } = useWindowSize();

  return (
    <div
      className={`${getDynamicWrapperClass(
        "page-wrapper",
        isBeforeEventStart
      )}`}
    >
      <PageBanner bannerImage={bannerImage} />
      <div
        className={`page-wrapper-content page-wrapper-content-${getResponsiveClassName(
          width
        )}`}
      >
        <div
          className={`${getDynamicWrapperClass(
            "page-bg-container",
            isBeforeEventStart
          )} ${getResponsiveClassName(width)}`}
        />
        {children}
        <ScrollToTopButton />
      </div>
    </div>
  );
};

export default PageWrapper;
