import React, { FC, useState } from "react";
import { useMutation, queryCache } from "react-query";
import { postEventClaim } from "../../ApiHelper";
import "./ClaimPoints.scss";
import useWindowSize from "../../utils/useWindowSize";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";

const ClaimPoints: FC = () => {
  const [claimState, setClaimState] = useState("home");
  const [invalid, setIsInvalid] = useState(false);
  const [claimsCode, setClaimsCode] = useState("");

  const { width } = useWindowSize();

  const getInnerContainerClass = width =>
    `claim-points-bar-${getResponsiveClassName(width)}`;
  const getFormClass = width =>
    `claim-points-form-inline-${getResponsiveClassName(width)}`;
  const getDescriptionContainerClass = width =>
    `description-container-${getResponsiveClassName(width)}`;
  const getInputClass = () =>
    invalid
      ? "claim-points-input-error"
      : claimState === "success"
      ? "claim-points-input-success"
      : "";

  const [mutate] = useMutation(postEventClaim, {
    onSuccess: data => {
      if (data === null) {
        setIsInvalid(true);
      } else {
        setClaimState("success");
      }
    },
    onSettled: () => {
      queryCache.invalidateQueries("participant");
      queryCache.invalidateQueries("participants");
    }
  });

  const handleClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (claimsCode.length === 6) {
      mutate(claimsCode);
    }
  };

  const clearSubmissionState = () => {
    setClaimState("home");
    setIsInvalid(false);
  };

  return (
    <section className="section-container">
      <div className="section-title">CLAIM POINTS</div>
      <div className={`claim-points-bar ${getInnerContainerClass(width)}`}>
        <div
          className={`claim-points-description-container ${getDescriptionContainerClass(
            width
          )}`}
        >
          <div className="claim-points-header">
            Attended an event? Claim your points now!
          </div>
          <p>
            Enter your unique code here to earn points! If you&apos;ve lost your
            event code, ping your event lead so you don&apos;t miss out on
            points!
          </p>
        </div>
        <form className={`claim-points-form-inline ${getFormClass(width)}`}>
          <div className="claim-points-enter-code-container">
            <h2 className="claim-points-header">Event code</h2>
            <br></br>
            <input
              className={`claim-points-input ${getInputClass()}`}
              type="text"
              name="code"
              maxLength={6}
              onChange={event => setClaimsCode(event.target.value)}
              onFocus={() => clearSubmissionState()}
              placeholder="Enter Code"
            />
            <div id="claim-points-input-message">
              {invalid
                ? "Uh oh! Please try again."
                : claimState && claimState === "success"
                ? "Success!"
                : ""}
            </div>
          </div>
          <div className="claim-points-button-container">
            <button
              className={`app-button app-button-primary test ${
                claimsCode.length !== 6 ||
                invalid === true ||
                claimState === "success"
                  ? "disabled"
                  : ""
              }`}
              type="button"
              value="xSubmit"
              onClick={handleClick}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ClaimPoints;
