import React from "react";
import "./SplashPage.scss";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import SplashDesktop from "../../assets/images/pages/privacy-week-splash.jpg";
import SplashTablet from "../../assets/images/pages/privacy-week-splash-tablet-mobile.png";
import SplashMobile from "../../assets/images/pages/privacy-week-splash-mobile.png";

const { TABLET_MAX, MOBILE_MAX } = deviceWidthBreakpoints;

const renderBackgroundMedia = (width: number) =>
  width > TABLET_MAX
    ? renderDesktopBackgroundImg(width)
    : width > MOBILE_MAX
    ? renderTabletBackgroundImg(width)
    : renderMobileBackgroundImg(width);

const renderDesktopBackgroundImg = (width): JSX.Element => (
  <img
    src={SplashDesktop}
    alt="Desktop Splash Page displaying a spaceship on a foreign planet with a tag reading 'SS PRIVACY; DEEP SPACE RESEARCH SHIP, ON A MISSION TO PROTECT SLALOMITES DATA.'"
    className="splash-background-image__desktop"
  ></img>
);
const renderTabletBackgroundImg = (width): JSX.Element => (
  <img
    src={SplashTablet}
    alt="Tablet Splash Page displaying a spaceship on a foreign planet with a tag reading 'SS PRIVACY; DEEP SPACE RESEARCH SHIP, ON A MISSION TO PROTECT SLALOMITES DATA.'"
    className="splash-background-image"
  ></img>
);

const renderMobileBackgroundImg = (width): JSX.Element => (
  <img
    src={SplashMobile}
    alt="Mobile Splash Page displaying a spaceship on a foreign planet with a tag reading 'SS PRIVACY; DEEP SPACE RESEARCH SHIP, ON A MISSION TO PROTECT SLALOMITES DATA.'"
    className="splash-background-image"
  ></img>
);

const SplashPage = () => {
  const { width } = useWindowSize();

  return (
    <main className="splash-container">{renderBackgroundMedia(width)}</main>
  );
};

export default SplashPage;
