import React from "react";
import PropTypes from "prop-types";
import ProfileCardList from "../ProfileCardList/ProfileCardList";
import pointsIcon from "../../assets/images/database-black.svg";
import "./EventCard.scss";
import { useQuery } from "react-query";
import { getEventDetail } from "../../ApiHelper";
import {
  startTimeStr,
  endTimeStr,
  timezoneStr,
  dateStr,
  shortenContent,
  extendContent
} from "./";
import LoadingSpinner from "../LoadingSpinner";
import { useState } from "react";
import Card from "../Card/Card";

const renderEventCard = ({
  startDateTime,
  endDateTime,
  title,
  points,
  content,
  hostMarket
}) => (
  <div className="event-details">
    <div className={"event-card-title"}>{title}</div>
    <div className="event-points">
      <div style={{ marginRight: "20px" }}>
        <img
          src={pointsIcon}
          alt="event-card-points-icon"
          className="event-card-points-icon"
        />
      </div>
      {points} Points
    </div>
    <div className="event-date">
      {`${startDateTime && dateStr(startDateTime)} at `}
      {startDateTime && startTimeStr(startDateTime)} -{" "}
      {endDateTime && endTimeStr(endDateTime)} {timezoneStr}
    </div>
    <p className="event-content">{shortenContent(content)}</p>
    <p className="event-location">
      <b>Market: </b> {hostMarket}
    </p>
  </div>
);

const EventDetails = ({ eventId }) => {
  const {
    data: eventDetailData,
    error: eventDetailError,
    isLoading: eventDetailIsLoading
  } = useQuery(["eventDetail", eventId], getEventDetail, {
    enabled: eventId
  });
  let content = "";
  let speakerProfiles = [];
  if (!eventDetailIsLoading) {
    content = eventDetailData?.content;
    speakerProfiles = eventDetailData?.speakerProfiles;
  }
  return (
    <LoadingSpinner
      isLoading={eventDetailError || eventDetailData === undefined}
      error={eventDetailIsLoading}
      size="massive"
    >
      <div className="event-details-expanded">{extendContent(content)}</div>
      {speakerProfiles && (
        <div style={{ marginBottom: "30px" }}>
          <ProfileCardList participants={speakerProfiles} />
        </div>
      )}
    </LoadingSpinner>
  );
};

const getButtonTitle = url =>
  url.startsWith("https://teams.microsoft.com")
    ? "Contact for invite"
    : "Add to calendar";

const renderEventCardOrDetails = (displayDetails, eventCardProps) => {
  return !displayDetails ? (
    renderEventCard(eventCardProps)
  ) : (
    <EventDetails eventId={eventCardProps?.eventId} />
  );
};

const EventCard = props => {
  const [displayDetails, setDisplayDetails] = useState(false);
  return (
    <Card
      containerClass="event-card-container"
      contentStyle={{
        height: "650px",
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      {renderEventCardOrDetails(displayDetails, props)}
      <div>
        <div className="event-card-primary-btn-wrapper">
          <a
            target="_blank"
            className="app-button app-button-primary add-to-calendar-btn"
            href={props.signupLink}
            rel="noopener noreferrer"
            id="add-to-calendar-btn"
          >
            {getButtonTitle(props.signupLink)}
          </a>
        </div>
        <button
          className="app-button app-button-secondary btn-content-color"
          type="button"
          onClick={() => {
            setDisplayDetails(!displayDetails);
          }}
        >
          {!displayDetails ? "Read more \u203A" : "Back \u203A"}
        </button>
      </div>
    </Card>
  );
};

EventCard.propTypes = {
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  title: PropTypes.string,
  points: PropTypes.number,
  content: PropTypes.string,
  signupLink: PropTypes.string,
  detail: PropTypes.bool,
  speakers: PropTypes.array,
  eventId: PropTypes.number
};

EventDetails.propTypes = {
  eventId: PropTypes.number
};

renderEventCard.propTypes = {
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  title: PropTypes.string,
  points: PropTypes.number,
  content: PropTypes.string
};

export default EventCard;
