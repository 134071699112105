import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getEvents } from "../../ApiHelper";
import eventsBanner from "../../assets/images/pages/events.jpg";
import ClaimPoints from "../../components/ClaimPoints/ClaimPoints";
import DropdownFilter from "../../components/Dropdown";
import EventCard from "../../components/EventCard";
import LoadingSpinner from "../../components/LoadingSpinner";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import Tooltip from "../../components/Tooltip/Tooltip";
import ViewRecording from "../../components/ViewRecording/ViewRecording";
import {
  getMainContainerClass,
  getPageContainerClass,
  getResponsiveBackgroundClass,
  getResponsiveClassName
} from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import { dayFilterOptions, filteredEvents, timeFilterOptions } from "./";
import "./EventsPage.scss";

function EventsPage({ showClaimPoints }) {
  const { width } = useWindowSize();

  //const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedDays, setSelectedDays] = useState(["0"]);
  const [selectedTimes, setSelectedTimes] = useState(["all"]);
  const [speakerProfiles, setSpeakerProfiles] = useState([]);
  const {
    data: eventsData,
    error: eventsError,
    isLoading: eventsAreLoading
  } = useQuery(["events"], getEvents);
  const filterContainerClass = `filter-container-${getResponsiveClassName(
    width
  )}`;

  const filterBlockClass = `filter-block-${getResponsiveClassName(width)}`;

  return (
    <ScrollToTop>
      <PageWrapper bannerImage={eventsBanner}>
        <div
          className={`hacktober-page events-page ${getResponsiveBackgroundClass(
            width
          )}`}
        >
          <div className={getPageContainerClass(width)}>
            <div className={`${getMainContainerClass(width)}`}>
              {showClaimPoints ? (
                <ClaimPoints />
              ) : (
                <div className="claim-points-spacer"></div>
              )}
              <div className="events-container">
                <div className="events-tooltip">
                  <div className="section-title">UPCOMING EVENTS</div>
                  <Tooltip>
                    <p>
                      Sign up for an event by adding it to your calendar below!
                      Make sure to open the downloaded file to Outlook.
                    </p>
                    <p>
                      Past events are available via the View Recordings button.
                    </p>
                  </Tooltip>
                </div>
                <div
                  className={
                    "app-card-display filter-container " + filterContainerClass
                  }
                >
                  <div>
                    <p>View recording</p>
                    <ViewRecording
                      value="View Recordings"
                      onClick={() =>
                        window.open(
                          "https://twodegrees1.sharepoint.com/teams/PrivacySlalom/DPW%202024%20Event%20Recordings/Forms/All%20media.aspx?npsAction=createList",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={filterBlockClass}>
                    <p>Filter by day</p>
                    <DropdownFilter
                      placeholder="Select day"
                      onChange={(event, data) => setSelectedDays(data.value)}
                      color="#deff4d"
                      options={dayFilterOptions}
                      multiple
                      selection
                      clearable
                      className="events-filter"
                    />
                  </div>
                  <div>
                    <p>Filter by time</p>
                    <DropdownFilter
                      placeholder="Select time"
                      onChange={(event, data) => {
                        setSelectedTimes(data.value);
                      }}
                      options={timeFilterOptions}
                      multiple
                      selection
                      clearable
                      className="events-filter"
                    />
                  </div>
                </div>
                <div className="event-card-grid">
                  <LoadingSpinner
                    isLoading={eventsAreLoading}
                    error={eventsError}
                    size="massive"
                    color="#443A84"
                  >
                    {filteredEvents(
                      eventsData,
                      [],
                      selectedDays,
                      selectedTimes
                    ).map(event => {
                      return (
                        moment(event.startDateTime).isSameOrAfter(moment()) && (
                          <EventCard
                            key={event.eventId}
                            {...event}
                            speakerProfiles={speakerProfiles}
                            setSpeakerProfiles={setSpeakerProfiles}
                          />
                        )
                      );
                    })}
                  </LoadingSpinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </ScrollToTop>
  );
}

EventsPage.propTypes = {
  showClaimPoints: PropTypes.bool
};

export default EventsPage;
