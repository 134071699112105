/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "react-router-dom";
import pointsIcon from "../../assets/images/database-black.svg";
import LinkButton from "../../components/LinkButton/LinkButton";
import { EButtonStyle } from "../../utils/buttonUtils";
import Button from "../Button/Button";
import Card from "../Card/Card";
import Tooltip from "../Tooltip/Tooltip";
import "./OtherWaysToParticipate.scss";

const PRIVACY_SHAREPOINT_URL = `https://privacy.slalom.com`;

const buttonLabels = {
  CLAIM_POINTS: "Claim Points",
  ONE_PASSWORD: "Join 1Password",
  SCAVENGER_HUNT: "Scavenger Hunt",
  ANNUAL_TRAINING: "Annual Privacy Training",
  ATTEND_EVENTS: "Live Webinars",
  PRIVACY_MASCOT: "Privacy Mascot Idea"
};

const MAIL_TO_SECURITY = "mailto:security@slalom.com";
const MAIL_TO_PRIVACY = "mailto:privacy@slalom.com";
const TRAINING_LINK = "https://slalom.securityeducation.com/";
const MASCOT_LINK =
  "https://twodegrees1.sharepoint.com/:b:/r/teams/PrivacySlalom/Privacy%20Resource%20Library/2024%20DPW%20Mascot%20Submission%20Rules2.pdf?csf=1&web=1&e=zESiam";

interface CardProps {
  title: string;
  points: number;
  description: React.ReactElement;
  buttonValue: string;
  buttonHref: string;
}

const OtherWaysToParticipateCard: React.FC<CardProps> = ({
  title,
  points,
  description,
  buttonValue,
  buttonHref
}): React.ReactElement => {
  return (
    <Card>
      <div>
        <h2>{title}</h2>
        {points && (
          <div className="tile-points">
            <img src={pointsIcon} className="points-icon" alt="points icon" />
            <div className="points-value">{points} Points</div>
          </div>
        )}
        {description}
      </div>
      <div className="tile-button-container">
        {buttonHref === "event" ? (
          <Link to="/events">
            <Button
              style={EButtonStyle.PRIMARY}
              type="button"
              value={buttonValue}
              onClick={() => null}
            />
          </Link>
        ) : (
          <LinkButton
            text={buttonValue}
            style={EButtonStyle.PRIMARY}
            href={buttonHref}
            newTab={true}
          />
        )}
      </div>
    </Card>
  );
};

const onePasswordDescription = () => (
  <p className="tile-description">
    Request an invite from security@slalom.com and mention it is part of
    Hacktober in order to earn points!
    <br />
    <br />
    <i>
      If you already have an active 1Password account, your points have been
      added.
    </i>
  </p>
);

const scavengerHuntDescription = () => (
  <p className="tile-description">
    Help us decode a Data Privacy message with hints on the Privacy@Slalom
    Sharepoint site. Find your way to the FAQ page, look for six words, arrange
    them to create a message, and send your answer to{" "}
    <a href={MAIL_TO_PRIVACY} target="_blank" rel="noopener noreferrer">
      privacy@slalom.com
    </a>{" "}
  </p>
);

const trainingDescription = () => (
  <p className="tile-description">
    Complete your annual Data Privacy Training and submit your training
    completion code on the Events page to earn points!
  </p>
);

const attendEventsDescription = () => (
  <p className="tile-description">
    Attend these fun and informative webinars to earn 300 points per each one
    that you attend or watch the recordings.
  </p>
);

const mascotIdeaDescription = () => (
  <p className="tile-description">
    Win a chance to get AirPods by helping the Privacy team come up with ideas
    for a Privacy Team Mascot! Provide a visual work of art (drawing, 3D design,
    etc.) to{" "}
    <a href={MAIL_TO_PRIVACY} target="_blank" rel="noopener noreferrer">
      privacy@slalom.com
    </a>{" "}
    by Jan 25th. You need to have completed the annual Data Privacy Training
    2024 to be eligible. We can&apos;t wait to see your creations! If we use
    your idea, you will be notified via email by Feb. 9th with information on
    how to claim your AirPods. Please see full contest rules{" "}
    <a href={MASCOT_LINK} target="_blank" rel="noopener noreferrer">
      here
    </a>
    .
  </p>
);

interface OtherwaysToParticipateCardContent {
  title: string;
  points: number;
  descriptionComponent: () => React.ReactElement;
  button: { value: string; href: string };
}
const cardContent: OtherwaysToParticipateCardContent[] = [
  {
    title: "Join Slalom on 1Password",
    points: 300,
    descriptionComponent: onePasswordDescription,
    button: { value: buttonLabels.ONE_PASSWORD, href: MAIL_TO_SECURITY }
  },
  {
    title: "Scavenger Hunt",
    points: 300,
    descriptionComponent: scavengerHuntDescription,
    button: { value: buttonLabels.SCAVENGER_HUNT, href: PRIVACY_SHAREPOINT_URL }
  },
  {
    title: "Training",
    points: 500,
    descriptionComponent: trainingDescription,
    button: {
      value: buttonLabels.ANNUAL_TRAINING,
      href: TRAINING_LINK
    }
  },
  {
    title: "Attend 4 Live Webinars or Recordings",
    points: undefined,
    descriptionComponent: attendEventsDescription,
    button: {
      value: buttonLabels.ATTEND_EVENTS,
      href: "event"
    }
  },
  {
    title: "Privacy Mascot Submission",
    points: 500,
    descriptionComponent: mascotIdeaDescription,
    button: {
      value: buttonLabels.PRIVACY_MASCOT,
      href: MAIL_TO_PRIVACY
    }
  }
];

const OtherWaysToParticipate = () => {
  return (
    <div className="other-ways-to-earn-page section-container">
      <div className="section-header">
        <div className="section-title">OTHER WAYS TO EARN POINTS</div>
        <Tooltip>
          <p className="OtherWaysToEarnPointsToolTip">
            Points for these activities are added manually and will appear by
            the end of the month
          </p>
        </Tooltip>
      </div>
      <div className="other-ways-to-earn-container">
        {cardContent.map(
          ({ title, points, descriptionComponent, button }, idx) => (
            <OtherWaysToParticipateCard
              key={`other-ways-to-earn-card-${idx}`}
              title={title}
              points={points}
              description={descriptionComponent()}
              buttonValue={button.value}
              buttonHref={button.href}
            />
          )
        )}
      </div>
    </div>
  );
};

export default OtherWaysToParticipate;
