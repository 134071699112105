import React from "react";
import "./HomePage.scss";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import QuizOfTheDay from "../../components/QuizOfTheDay/QuizOfTheDay";
import OtherWaysToParticipate from "../../components/OtherWaysToParticipate";

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import Securyeti from "../../components/Securyeti/Securyeti";

import homeBannerMd from "../../assets/images/home-page-header-md.jpg";
import homeBannerXl from "../../assets/images/home-page-header-xl.jpg";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";

const { MOBILE_MAX } = deviceWidthBreakpoints;

function HomePage() {
  const { width } = useWindowSize();
  const homeBanner = width > MOBILE_MAX ? homeBannerXl : homeBannerMd;

  return (
    <ScrollToTop>
      <PageWrapper bannerImage={homeBanner}>
        <QuizOfTheDay />
        <Leaderboard />
        <Securyeti />
        <OtherWaysToParticipate />
      </PageWrapper>
    </ScrollToTop>
  );
}
export default HomePage;
