import React, { FC } from "react";
import Button from "../../components/Button/Button";
import { EButtonStyle } from "../../utils/buttonUtils";
import { Event } from "../../utils/consts/event";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import "./WrapPage.scss";
const event = Event;
const { MOBILE_MAX } = deviceWidthBreakpoints;
export const SHAREPOINT_LINK = event.wrapPage.sharePointLink;
export const SIGNUP_LINK = event.wrapPage.signUpLink;

const renderButtonContainer = (width: number): JSX.Element => (
  <div className={`wrapup-button-container-${getResponsiveClassName(width)}`}>
    <div className={width > MOBILE_MAX ? "top-buttons" : "top-buttons-tablet"}>
      <Button
        style={EButtonStyle.PRIMARY}
        type="button"
        value="Visit our Sharepoint site"
        onClick={() => window.open(SHAREPOINT_LINK, "_blank")}
      />
      <Button
        style={EButtonStyle.PRIMARY}
        type="button"
        value="Join the Zenith Build Team!"
        onClick={() => window.open(SIGNUP_LINK, "_blank")}
      />
      <Button
        style={EButtonStyle.PRIMARY}
        type="button"
        value="Give Feedback"
        onClick={() => (window.location.href = "mailto:privacy@slalom.com")}
      />
    </div>
  </div>
);

const renderBackgroundImg = (): JSX.Element => <></>;

const wrapupPageContainerResponsive = (width: number): string => {
  return `wrapup-page-container-${getResponsiveClassName(width)}`;
};

const WrapPage: FC = () => {
  const { width } = useWindowSize();

  return (
    <>
      <div className="wrap-page-bg-container wrap-page">
        {renderBackgroundImg()}
        <div
          className={`wrap-page-container ${wrapupPageContainerResponsive(
            width
          )}`}
        >
          <div className={`wrapup-header-${getResponsiveClassName(width)}`}>
            {event.wrapPage.title}
          </div>
          <p className={`wrapup-content-${getResponsiveClassName(width)}`}>
            {event.wrapPage.message}{" "}
            <a
              href={"mailto:privacy@slalom.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy@slalom.com
            </a>
          </p>
          {renderButtonContainer(width)}
        </div>
      </div>
    </>
  );
};

export default WrapPage;
