import React from "react";
import { Link } from "react-router-dom";
import mobileBackground from "../../assets/images/pages/privacy-week-splash-mobile.png";
import tabletBackground from "../../assets/images/pages/privacy-week-splash-tablet-mobile.png";
import desktopVideo from "../../assets/videos/pre-launch-animation-final-HQ.mp4";
import Button from "../../components/Button/Button";
import LinkButton from "../../components/LinkButton/LinkButton";
import { EButtonStyle } from "../../utils/buttonUtils";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import "./PrelaunchPage.scss";

const { TABLET_MAX, MOBILE_MAX } = deviceWidthBreakpoints;

const renderBackgroundMedia = (width: number) =>
  width > TABLET_MAX
    ? renderVideoBackground(width)
    : width > MOBILE_MAX
    ? renderTabletBackgroundImg(width)
    : renderMobileBackgroundImg(width);

const renderTabletBackgroundImg = (width): JSX.Element => (
  <img
    src={tabletBackground}
    alt="Tablet Pre-Launch Page displaying a spaceship on a foreign planet with a tag reading 'SPACESHIP PRIVACY; DEEP SPACE RESEARCH SHIP, ON A MISSION TO PROTECT SLALOMITES DATA.'"
    className="prelaunch-background-image"
  ></img>
);

const renderMobileBackgroundImg = (width): JSX.Element => (
  <img
    src={mobileBackground}
    alt="Mobile Pre-Launch Page displaying a spaceship on a foreign planet with a tag reading 'SPACESHIP PRIVACY; DEEP SPACE RESEARCH SHIP, ON A MISSION TO PROTECT SLALOMITES DATA.'"
    className="prelaunch-background-image"
  ></img>
);

const renderVideoBackground = (width): JSX.Element => (
  <div>
    <video
      autoPlay
      loop
      muted
      preload="true"
      className="prelaunch-background-video"
    >
      <source src={desktopVideo} type="video/mp4" />
    </video>
  </div>
);

const PrelaunchPage = () => {
  const { width } = useWindowSize();

  return (
    <>
      <div className="prelaunch-video-overlay" />
      <main
        className={`prelaunch-container prelaunch-container-${getResponsiveClassName(
          width
        )}`}
      >
        {renderBackgroundMedia(width)}
        <section className="prelaunch-topic-container">
          <div
            className={`button-container-${getResponsiveClassName(
              width
            )} button-mt`}
          >
            <Link to="/events" target="_blank">
              <Button
                style={EButtonStyle.PRIMARY}
                type="button"
                value="RSVP"
                onClick={() => null}
              />
            </Link>
            <LinkButton
              style={EButtonStyle.PRIMARY}
              text="Open Spotify"
              href={
                "https://open.spotify.com/playlist/1RIPeshWerFJUBXuwUbdSB?si=8a82c902ec8941e7"
              }
              newTab={true}
            />
            <Link to="/faqs" target="_blank">
              <Button
                style={EButtonStyle.PRIMARY}
                type="button"
                value="FAQ"
                onClick={() => null}
              />
            </Link>
          </div>
        </section>
      </main>
    </>
  );
};

export default PrelaunchPage;
