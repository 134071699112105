import React from "react";
import { Link } from "react-router-dom";
import aboutBannerDesktop from "../../assets/images/about-bg-desktop.jpg";
import aboutBannerTablet from "../../assets/images/about-bg-tablet.jpg";
import AboutInfoList from "../../components/AboutInfoList/AboutInfoList";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import { EButtonStyle } from "../../utils/buttonUtils";
import { Event } from "../../utils/consts/event";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import "./AboutPage.scss";

const { MOBILE_MAX } = deviceWidthBreakpoints;

const getAboutHeaderResponsiveClass = width =>
  `about-header-${getResponsiveClassName(width)}`;

const getAboutWelcomeBlockResponsiveClass = width =>
  `about-welcome-${getResponsiveClassName(width)}`;

const getAboutWelcomeSectionResponsiveClass = width =>
  `welcome-section-${getResponsiveClassName(width)}`;

const getAboutContactSectionResponsiveClass = width =>
  `contact-section-container-${getResponsiveClassName(width)}`;

const getAboutNotesSectionResponsiveClass = width =>
  `about-notes-section-${getResponsiveClassName(width)}`;
const event = Event;

const openInNewTab = url => {
  window.open(url, "_blank", "noreferrer");
};

const AboutPage = () => {
  const parseContentLinks = content => {
    return content.split(" ").map((part, index: number) =>
      part.endsWith("slalom.com") ? (
        <>
          <a href={`mailto:${part}`} target="_blank" rel="noopener noreferrer">
            {part}
          </a>{" "}
        </>
      ) : (
        part + " "
      )
    );
  };
  const { width } = useWindowSize();
  const aboutBanner =
    width > MOBILE_MAX ? aboutBannerDesktop : aboutBannerTablet;
  const url =
    "https://sparkthink.slalom.com/survey/s/4b2b2497-306a-4311-886b-5a6b4cbc4d53";
  return (
    <ScrollToTop>
      <PageWrapper bannerImage={aboutBanner}>
        <div className="about-container">
          <section className={`${getAboutWelcomeBlockResponsiveClass(width)}`}>
            <section
              className={`welcome-section ${getAboutWelcomeSectionResponsiveClass(
                width
              )}`}
            >
              <div
                className={`about-header ${getAboutHeaderResponsiveClass(
                  width
                )}`}
              >
                WELCOME TO SLALOM DATA PRIVACY WEEK
              </div>
              <div
                className={`welcome-section ${getAboutNotesSectionResponsiveClass(
                  width
                )} ${getAboutWelcomeSectionResponsiveClass(
                  width
                )} ${getAboutWelcomeBlockResponsiveClass(width)}`}
              >
                <Card>
                  <div className="about-page-text-body-container">
                    <div className="about-section-title">
                      A NOTE FROM THE DPW TEAM
                    </div>
                    {event.aboutPage.eventIntroTextOne}
                    <br />
                    <br />
                    <div>
                      {parseContentLinks(
                        event.aboutPage.eventIntroSecondaryTextOne
                      )}
                    </div>
                  </div>
                </Card>
                <Card>
                  <div className="about-page-text-body-container">
                    <div className="about-section-title">
                      A NOTE FROM THE INFOSEC TEAM
                    </div>
                    {event.aboutPage.eventIntroTextTwo}
                    <br />
                  </div>
                </Card>
              </div>
            </section>
          </section>
          <section
            className={`welcome-section ${getAboutContactSectionResponsiveClass(
              width
            )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
          >
            <Card
              containerClass={`contact-card-${getResponsiveClassName(width)}`}
            >
              <div className="about-page-text-body-container">
                <div className="contact-section-title">Got feedback?</div>
                {event.aboutPage.aboutEventsFeedbackText}
                <div className="flexible"></div>
                <div
                  className={`about-page-btn-container about-page-card-footer-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <a
                    href="mailto: privacy@slalom.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="app-button-primary"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </Card>
            <Card
              containerClass={`contact-card-${getResponsiveClassName(width)}`}
            >
              <div className="about-page-text-body-container">
                <div className="contact-section-title">
                  Join the Zenith Build Team!
                </div>
                {event.aboutPage.aboutEventsJoinText}
                <div
                  className={`about-page-card-footer-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <Link
                    to="/events"
                    target="_blank"
                    className="about-page-btn-container"
                  >
                    <Button
                      style={EButtonStyle.PRIMARY}
                      type="button"
                      value="Join Us"
                      onClick={() => openInNewTab(url)}
                    />
                  </Link>
                </div>
              </div>
            </Card>
            <Card
              containerClass={`contact-card-${getResponsiveClassName(width)}`}
            >
              <div className="about-page-text-body-container">
                <div className="contact-section-title">Caught a bug?</div>
                {event.aboutPage.aboutEventsBugText}
                <div className="flexible"></div>
                <div
                  className={`about-page-btn-container about-page-card-footer-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <a
                    href="mailto: zenith@slalom.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="app-button-primary"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </Card>
          </section>
          {Object.keys(event.aboutPage.aboutTeams).map((team, idx) => {
            return (
              <div key={idx}>
                {team === "Ownership" ? (
                  <div>
                    <div
                      className={`about-header ${getAboutHeaderResponsiveClass(
                        width
                      )}`}
                    >
                      SOLUTION OWNERSHIP
                    </div>
                    <AboutInfoList
                      participants={event.aboutPage.aboutTeams[team]}
                    />
                  </div>
                ) : team === "Quality" ? (
                  <div>
                    <div
                      className={`about-header ${getAboutHeaderResponsiveClass(
                        width
                      )}`}
                    >
                      Quality Engineering
                    </div>
                    <AboutInfoList
                      participants={event.aboutPage.aboutTeams[team]}
                    />
                  </div>
                ) : (
                  <div>
                    <div
                      className={`about-header ${getAboutHeaderResponsiveClass(
                        width
                      )}`}
                    >
                      {team}
                    </div>
                    <AboutInfoList
                      participants={event.aboutPage.aboutTeams[team]}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </PageWrapper>
    </ScrollToTop>
  );
};

export default AboutPage;
