import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { EButtonStyle } from "../../utils/buttonUtils";
import {
  getResponsiveBackgroundClass,
  getResponsiveClassName
} from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import "./ErrorPage.scss";

interface ErrorPageProps {
  setShowNavBars: (setVal: boolean) => void;
}

const getBtnResponsiveClass = (width: number): string => {
  return `error-btn-container-${getResponsiveClassName(width)}`;
};
const getErrorPageContainerClass = (width: number): string => {
  return `error-page-container-${getResponsiveClassName(width)}`;
};
const mobileOrDesktop = (width: number): string => {
  return `error-page-${getResponsiveClassName(width)}`;
};

const ErrorPage = ({ setShowNavBars }: ErrorPageProps) => {
  const { width } = useWindowSize();

  useEffect(() => {
    setShowNavBars(false);
  }, [setShowNavBars]);

  return (
    <div
      className={`hacktober-page ${mobileOrDesktop(
        width
      )} ${getResponsiveBackgroundClass(width)}`}
    >
      <div className={getErrorPageContainerClass(width)}>
        <div className={getBtnResponsiveClass(width)}>
          <Link to="/">
            <Button
              style={EButtonStyle.PRIMARY}
              type="button"
              value="Return Home"
              onClick={() => null}
            />
          </Link>
          <a
            href="mailto: privacy@slalom.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              style={EButtonStyle.SECONDARY}
              type="button"
              value="Contact DPW Team >"
              onClick={() => null}
            />
            {/* <button className="app-button app-button-secondary error-btn-text-only">
              <div className="btn-label">{`Contact DPW Team >`}</div>
            </button> */}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
