import React from "react";
import homeBannerMd from "../../assets/images/home-page-header-md.jpg";
import homeBannerXl from "../../assets/images/home-page-header-xl.jpg";
import Card from "../../components/Card/Card";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import LinkButton from "../../components/LinkButton/LinkButton";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PrizesList from "../../components/PrizesList/PrizesList";
import ScrollToTop from "../../components/ScrollToTop";
import { Event } from "../../utils/consts/event";
import {
  getMainContainerClass,
  getPageContainerClass,
  getResponsiveBackgroundClass
} from "../../utils/responsiveClassHelpers";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import "./WinnersPage.scss";

const joinUsSurveyLink =
  "https://sparkthink.slalom.com/survey/s/4b2b2497-306a-4311-886b-5a6b4cbc4d53";

const prizesList: { title: string; text: string }[] = [
  {
    title: "1st Place Global Winner",
    text: "AirPods"
  },
  {
    title: "2nd Place Global Winner",
    text: "$100"
  },
  {
    title: "3rd Place Global Winner",
    text: "$50"
  },
  {
    title: "4th Place Global Winner",
    text: "$25"
  },
  {
    title: "5th Place Global Winner",
    text: "$25"
  }
];

const { MOBILE_MAX } = deviceWidthBreakpoints;

const WinnersPage = () => {
  const { width } = useWindowSize();
  const bannerImg = width > MOBILE_MAX ? homeBannerXl : homeBannerMd;

  return (
    <ScrollToTop>
      <PageWrapper bannerImage={bannerImg}>
        <div
          className={`hacktober-page winners-page ${getResponsiveBackgroundClass(
            width
          )}`}
        >
          <div className={getPageContainerClass(width)}>
            <div className={getMainContainerClass(width)}>
              <div className="section-container" id="winners-note-section">
                <Card title={Event.winnersPage.title}>
                  <h2>{Event.winnersPage.section1Title}</h2>
                  <div>
                    <p>
                      {Event.winnersPage.section1Description}{" "}
                      <a
                        href={"mailto:privacy@slalom.com"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacy@slalom.com
                      </a>
                    </p>
                  </div>
                </Card>
              </div>
              <div
                className="section-container flex-container"
                id="winners-info-section"
              >
                <Card>
                  <React.Fragment>
                    <h2>{Event.winnersPage.feedbackTitle}</h2>
                    <p>{Event.winnersPage.feedbackText}</p>
                    <LinkButton
                      text="Contact Us"
                      href="mailto:privacy@slalom.com"
                      newTab={true}
                    />
                  </React.Fragment>
                </Card>
                <Card>
                  <React.Fragment>
                    <h2>{Event.winnersPage.joinTitle}</h2>
                    <p>{Event.winnersPage.joinText}</p>
                    <LinkButton
                      text="Join Us"
                      href={joinUsSurveyLink}
                      newTab={true}
                    />
                  </React.Fragment>
                </Card>
                <Card>
                  <React.Fragment>
                    <h2>{Event.winnersPage.bugTitle}</h2>
                    <p>{Event.winnersPage.bugText}</p>
                    <LinkButton
                      text="Contact Us"
                      href="mailto:zenith@slalom.com"
                      newTab={true}
                    />
                  </React.Fragment>
                </Card>
              </div>
              <Leaderboard isLocalLeaderBoard={false} />
              <PrizesList prizes={prizesList}></PrizesList>
            </div>
          </div>
        </div>
      </PageWrapper>
    </ScrollToTop>
  );
};

export default WinnersPage;
