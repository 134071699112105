import React, { FC } from "react";
import "./ProfileCard.scss";

type ParticipantProps = {
  image: any;
  firstName: string;
  lastName: string;
  market: string;
  user: string;
};
interface ProfileCardProps {
  participant: ParticipantProps;
}

const provideImgOrFallback = (image: any) => {
  return image?.data
    ? `data:image/jpeg;base64,${image.data}`
    : require("../../assets/images/scl-avatar.png");
};

const renderProfileImg = (image: any) => (
  <img
    src={provideImgOrFallback(image)}
    alt="profile pic"
    className="profile-img"
    data-testid="profile-img"
  />
);
const renderContactInfo = (
  firstName: string,
  lastName: string,
  market: string,
  email: string
) => (
  <div className="contact-info" data-testid="contact-info">
    <div className="contact-name" data-testid="contact-name">
      <span data-testid="contact-first-name">{firstName}</span>
      <span data-testid="contact-last-name">{lastName}</span>
    </div>
    <span className="contact-market" data-testid="contact-market">
      {market}
    </span>
    <a
      className="contact-email"
      href={`mailto:${email}`}
      data-testid="contact-email"
    >
      Email
    </a>
  </div>
);

const ProfileCard: FC<ProfileCardProps> = ({
  participant
}: ProfileCardProps): JSX.Element => {
  const { image, firstName, lastName, market, user } = participant;
  return (
    <div className="profile-card-container" data-testid="profile-card">
      {renderProfileImg(image)}
      {renderContactInfo(firstName, lastName, market, user)}
    </div>
  );
};

export default ProfileCard;
