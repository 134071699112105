import Amplify from "aws-amplify";
import React, { FC, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { refreshToken } from "./ApiHelper";
import "./App.scss";
import { handleUserAuth } from "./AuthHelper";
import Footer from "./components/Footer";
import TopNavigationBar from "./components/TopNavigationBar";
import { amplifyConfig } from "./config";
import AboutPage from "./pages/AboutPage";
import ErrorPage from "./pages/ErrorPage";
import EventsPage from "./pages/EventsPage";
import FAQsPage from "./pages/FAQsPage";
import HomePage from "./pages/HomePage";
import PrelaunchPage from "./pages/PrelaunchPage";
import SplashPage from "./pages/SplashPage";
import WinnersPage from "./pages/WinnersPage/WinnersPage";
import WrapPage from "./pages/WrapPage";
import {
  LOCAL_LOGIN_COMPLETE,
  LOCAL_USER_DATA
} from "./utils/consts/amplifyKeys";
import {
  eventHasEnded,
  isBeforeEventStart,
  showFullSite,
  showWinners
} from "./utils/eventUtil";

Amplify.configure(amplifyConfig);

// const currentDomain = /:\/\/([^/]+)/.exec(window.location.href)[1];
const url = window.location.href;
const isProd = url.includes("events.slalom.com");
const isTest = url.includes("events.testslalom.com");
const isDev = url.includes("events.devslalom.com");
const isLocal = url.includes("localhost:3000");

const App: FC = () => {
  const [showNavBars, setShowNavBars] = useState(true);
  const [isLoggedIn, setisLoggedIn] = useState(
    Boolean(localStorage.getItem(LOCAL_LOGIN_COMPLETE))
  );
  const [userData, setUserData] = useState(
    JSON.parse(String(localStorage.getItem(LOCAL_USER_DATA)))
  );

  refreshToken().then(() => {
    handleUserAuth(userData, setUserData, isLoggedIn, setisLoggedIn);
  });

  // const HeaderFooterWrapper: React.FC<{ children: any }> = ({
  //   children
  // }): React.ReactElement => (
  //   <React.Fragment>
  //     {children}
  //     <Footer />
  //   </React.Fragment>
  // );

  return (
    <div className="App">
      {isLoggedIn ? (
        <BrowserRouter>
          {/* {false && (
            <>
              {showNavBars && <TopNavigationBar />}
              <Switch>
                { <Route exact path="/" render={() => <WrapPage />} /> }
                <Route
                  path="/events"
                  render={() => <EventsPage showClaimPoints={true} />}
                />
                <Route path="/about" render={() => <AboutPage />} />
                <Route path="/faqs" render={() => <FAQs />} />
                <Route
                  render={() => <ErrorPage setShowNavBars={setShowNavBars} />}
                />
              </Switch>
              {showNavBars && <Footer />}
            </>
          )} */}
          {/*
            Pre-launch:
            This usually has an introductory page and links to view the upcoming event calendar and FAQs.
            There is no home page because the Question of the Day / Leaderboard should only show
            during the event dates.
          */}
          {(isProd || isDev || isLocal) && isBeforeEventStart && (
            <>
              <Switch>
                <Route exact path="/" render={() => <PrelaunchPage />} />
                <Route
                  path="/events"
                  render={() => <EventsPage showClaimPoints={false} />}
                />
                <Route path="/faqs" render={() => <FAQsPage />} />
                <Route
                  render={() => <ErrorPage setShowNavBars={setShowNavBars} />}
                />
              </Switch>
            </>
          )}
          {/*
            Main app:
            These are the main routes to render during the event.
          */}
          {(isProd || isDev || isLocal) && showFullSite && (
            <>
              {showNavBars && <TopNavigationBar />}
              <Switch>
                <Route exact path="/" render={() => <HomePage />} />
                <Route
                  path="/events"
                  render={() => <EventsPage showClaimPoints={true} />}
                />
                <Route path="/about" render={() => <AboutPage />} />
                <Route path="/faqs" render={() => <FAQsPage />} />
                <Route
                  render={() => <ErrorPage setShowNavBars={setShowNavBars} />}
                />
              </Switch>
              {showNavBars && <Footer />}
            </>
          )}
          {isTest && (
            <>
              {showNavBars && <TopNavigationBar />}
              <Switch>
                <Route exact path="/" render={() => <HomePage />} />
                <Route
                  path="/events"
                  render={() => <EventsPage showClaimPoints={true} />}
                />
                <Route path="/about" render={() => <AboutPage />} />
                <Route path="/faqs" render={() => <FAQsPage />} />
                <Route
                  render={() => <ErrorPage setShowNavBars={setShowNavBars} />}
                />
              </Switch>
              {showNavBars && <Footer />}
            </>
          )}
          {/*
            Wrap:
            Similar to the pre-launch, except this is rendered after the event ends,
            and has a screen that summarizes the event, gives shout-outs to particular teams, etc.
          */}
          {(isProd || isTest || isDev) && eventHasEnded && !showWinners && (
            <>
              <Switch>
                <Route exact path="/" render={() => <WrapPage />} />
                <Route
                  render={() => <ErrorPage setShowNavBars={setShowNavBars} />}
                />
              </Switch>
              {showNavBars && <Footer />}
            </>
          )}
          {(isProd || isTest || isDev) && showWinners && (
            <>
              {showNavBars && <TopNavigationBar />}
              <Switch>
                <Route exact path="/">
                  <Redirect to="/winners" />
                </Route>
                <Route exact path="/winners" render={() => <WinnersPage />} />
                <Route path="/about" render={() => <AboutPage />} />
                <Route
                  render={() => <ErrorPage setShowNavBars={setShowNavBars} />}
                />
              </Switch>
              {showNavBars && <Footer />}
            </>
          )}
        </BrowserRouter>
      ) : (
        /*
          Splash:
          Renders while the SSO auth flow is triggering, so a blank screen is not
          shown to the user prior to being redirected to the MSFT login screen.
        */
        <Route render={() => <SplashPage />} />
      )}
    </div>
  );
};

export default App;
