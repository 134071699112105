import React from "react";
import "./Button.scss";
import { EButtonStyle } from "../../utils/buttonUtils";

type Props = {
  style?: string;
  type?: "submit" | "button" | "reset";
  value?: string;
  disabled?: boolean;
  onClick: (event?: any) => void;
};

const Button: React.FC<Props> = ({
  style,
  type,
  value,
  disabled,
  onClick
}): React.ReactElement => {
  let buttonStyle = style || EButtonStyle.PRIMARY;
  const buttonClasses = ["app-button", `app-button-${buttonStyle}`];

  return (
    <div className="app-button-generic-container">
      <button
        className={buttonClasses.join(" ")}
        type={type}
        onClick={onClick}
        disabled={disabled}
      >
        <span>{value}</span>
      </button>
    </div>
  );
};

export default Button;
