import React from "react";
import { Link } from "react-router-dom";
import { eventHasEnded } from "../../utils/eventUtil";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import "./Footer.scss";

const Footer = () => {
  const { width } = useWindowSize();
  const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

  return (
    <div>
      <footer
        className={`${width < TABLET_MAX && "footer-tablet"} ${width <
          MOBILE_MAX && "footer-mobile"}`}
      >
        {!eventHasEnded && (
          <div className="footer-text-container">
            <p>
              Have questions? Visit the <Link to="/faqs">‘FAQ’ page</Link> to
              learn more.
            </p>
          </div>
        )}
        <div className="footer-text-container">
          <p>
            Have feedback? Please share with{" "}
            <a
              href="mailto: privacy@slalom.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy@slalom.com
            </a>
          </p>
        </div>
        <div className="footer-text-container">
          <p>
            Caught a bug? Report it to{" "}
            <a
              href="mailto: zenith@slalom.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              zenith@slalom.com
            </a>
          </p>
        </div>
      </footer>
      <footer
        className={`${width < TABLET_MAX && "footer-tablet"} ${width <
          MOBILE_MAX && "footer-mobile"}`}
      >
        <div className="footer-text-container-2">
          <p>
            Slalom Privacy Week and all images including mascots or logos is for
            Slalom Inc. Internal Use Only. These images are not to be
            distributed or copied publicly.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
