import React, { useState } from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import "./TopNavigationBar.scss";
import { getParticipant } from "../../ApiHelper";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import Rankings from "../Rankings";
import { showWinners } from "../../utils/eventUtil";
import { getDataImage } from "../../utils/imageUtils";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

enum ENavLink {
  Winners = "Winners",
  Events = "Events",
  Home = "Home",
  About = "About",
  Faqs = "FAQs"
}

const getNavLinks = () => {
  return showWinners
    ? [ENavLink.Winners, ENavLink.About]
    : [ENavLink.Home, ENavLink.Events, ENavLink.About, ENavLink.Faqs];
};

const NavLogo = () => (
  <NavLink to="/" className="nav-logo">
    <img
      src={require("../../assets/images/slalom_logo_white.png")}
      alt="logo"
    />
  </NavLink>
);

type NavLinksPropTypes = {
  navLinks: ENavLink[];
};

const NavLinks: React.FC<NavLinksPropTypes> = ({
  navLinks
}): React.ReactElement => (
  <div className="nav-container">
    {navLinks.map((link, index) => (
      <NavLink
        className="nav-link"
        key={index}
        exact={link === "Home"}
        to={link !== "Home" ? `/${link.toLowerCase().replace(/ /g, "")}` : "/"}
      >
        <span className="nav-link-text">{link}</span>
      </NavLink>
    ))}
  </div>
);

type NavHamburgerMenuProps = {
  width: number;
  participantData: any;
  navLinks: ENavLink[];
};

const NavHamburgerMenu: React.FC<NavHamburgerMenuProps> = ({
  width,
  participantData,
  navLinks
}): React.ReactElement => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const closeMenu = () => {
    setNavbarOpen(false);
  };

  return (
    <div className="nav-hamburger nav-Container">
      <div
        id="nav-icon"
        onClick={handleToggle}
        className={navbarOpen ? "open" : ""}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <ul
        className={`nav-menu ${
          navbarOpen
            ? `${
                width <= MOBILE_MAX && navbarOpen
                  ? "nav-show-mobile"
                  : "nav-show"
              }`
            : ""
        }`}
      >
        <li className="mobile-nav-rankings">
          <div>
            {width < MOBILE_MAX ? (
              <div>Local: #{participantData?.marketRank || "X"} </div>
            ) : null}
          </div>
          <div>
            {width < MOBILE_MAX ? (
              <div>Global: #{participantData?.globalRank || "X"} </div>
            ) : null}
          </div>
          <div>
            {width < MOBILE_MAX ? (
              <div>Points {participantData?.totalPoints || "0"} </div>
            ) : null}
          </div>
        </li>
        {navLinks.map((link, index) => (
          <li key={index} onClick={() => closeMenu()}>
            <NavLink
              className={"nav-link-open"}
              key={index}
              exact={link === "Home"}
              to={
                link !== "Home"
                  ? `/${link.toLowerCase().replace(/ /g, "")}`
                  : "/"
              }
            >
              {link}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

type NavUserAndPointsProps = {
  width: number;
  participantData: any;
  participantIsLoading: boolean;
};

const NavUserAndPoints: React.FC<NavUserAndPointsProps> = ({
  width,
  participantData,
  participantIsLoading
}): React.ReactElement => (
  <>
    <Rankings />
    <div className="nav-user-container">
      <span className="nav-points">
        {width > MOBILE_MAX ? (
          <span>{participantData?.totalPoints || "0"} Points</span>
        ) : null}
      </span>
      <span className="nav-user">
        {participantIsLoading ? (
          <Loader active={true} inline className="loading-spinner" size="big" />
        ) : (
          <img
            src={getDataImage(participantData?.image)}
            alt="profile pic"
            height="50"
            width="50"
            className={`nav-user-img ${!participantData?.image}`}
          />
        )}
      </span>
    </div>
  </>
);

const TopNavigationBar: React.FC = (): React.ReactElement => {
  const { data: participantData, isLoading: participantIsLoading } = useQuery(
    "participant",
    getParticipant
  );
  const { width } = useWindowSize();
  const navLinks = getNavLinks();
  return (
    <header id="12">
      <div className="navigation-bar-container">
        <nav className="nav">
          <NavLogo />
          <div className="nav-container">
            {width > TABLET_MAX && <NavLinks navLinks={navLinks} />}
            {width <= TABLET_MAX && (
              <NavHamburgerMenu
                width={width}
                participantData={participantData}
                navLinks={navLinks}
              />
            )}
            <NavUserAndPoints
              width={width}
              participantData={participantData}
              participantIsLoading={participantIsLoading}
            />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default TopNavigationBar;
