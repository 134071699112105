import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import InfoIcon from "../../assets/images/tooltip_info.svg";
import CloseIcon from "../../assets/images/tooltip_close.svg";
import "./Tooltip.scss";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";

const Tooltip = ({ children }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const tooltipContentRef = useRef(null);
  useDetectOutsideClick(tooltipContentRef, () => setTooltipVisible(false));

  return (
    <div className="tooltip-container">
      <img
        className="tooltip-icon"
        onClick={() => setTooltipVisible(true)}
        src={InfoIcon}
        alt="tooltip icon"
      />
      {tooltipVisible && (
        <div ref={tooltipContentRef} className="tooltip-content">
          <div className="tooltip-close">
            <img
              onClick={() => setTooltipVisible(false)}
              src={CloseIcon}
              alt="icon close"
            />
          </div>
          {children}
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any
};

export default Tooltip;
