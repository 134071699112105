import React from "react";
import { useQuery } from "react-query";
import CountUp from "react-countup";
import "./Rankings.scss";
import { getParticipant } from "../../ApiHelper";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";

const Rankings = () => {
  const { data: participantData } = useQuery(["participant"], getParticipant);
  const { width } = useWindowSize();
  const { MOBILE_MAX } = deviceWidthBreakpoints;

  return (
    <>
      <div className="rankings-container">
        <span className="local-ranking">
          {width > MOBILE_MAX ? <span>Local: #</span> : null}
          {width > MOBILE_MAX && participantData?.marketRank ? (
            <CountUp end={parseInt(participantData?.marketRank)} duration={2} />
          ) : (
            ""
          )}
        </span>
        <span className="global-ranking">
          {width > MOBILE_MAX ? <span>Global: #</span> : null}
          {width > MOBILE_MAX && participantData?.globalRank ? (
            <CountUp end={parseInt(participantData?.globalRank)} duration={2} />
          ) : (
            ""
          )}
        </span>
      </div>
    </>
  );
};

export default Rankings;
